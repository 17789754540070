import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PartnersDataTableItem, PartnerService } from 'src/app/services/partner/partner.service';
import { CreatePartnerDialogComponent } from './create-partner-dialog/create-partner-dialog.component';
import { EditPartnerDialogComponent } from './edit-partner-dialog/edit-partner-dialog.component';

@Component({
  selector: 'app-partners-list',
  templateUrl: './partners-list.component.html',
  styleUrls: ['./partners-list.component.scss']
})
export class PartnersListComponent implements OnInit {
  readonly dataSource = new MatTableDataSource<PartnersDataTableItem>();
  readonly displayedColumns: string[] = [
    'integration',
    'name',
    'api_key',
    'origin',
    'created_at',
    'edit',
  ];

  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private partnerService: PartnerService,
    private dialog: MatDialog,
  ) { }

  async ngOnInit() {
    await this.setDataTable();
  }

  createPartner(): void {
    const ref = this.dialog.open(CreatePartnerDialogComponent, {
      disableClose: true,
    });
    ref.afterClosed()
      .subscribe({
        next: async (result: boolean) => {
          if (!result) {
            return false;
          }

          await this.setDataTable();
        },
      });
  }

  editPartner(element: PartnersDataTableItem): void {
    const ref = this.dialog.open(EditPartnerDialogComponent, {
      data: element,
      disableClose: true,
    });
    ref.afterClosed()
      .subscribe({
        next: async (result: boolean) => {
          if (!result) {
            return false;
          }

          await this.setDataTable();
        },
      });
  }

  private async setDataTable() {
    const result = await this.partnerService.getPartnersDataTable();
    this.dataSource.data = result.data;
  }
}
