<div class="content">
  <div class="selectors-box flex column">
    <!-- CLIENTS filter -->
    <div class="selector-row flex vcenter">
      <payee-form-field>
        <input payee-input [formControl]="clientFormControl" />
        <span payee-label>Hitelező neve</span>
      </payee-form-field>
      <mat-progress-spinner
        *ngIf="tableLoading"
        color="accent"
        diameter="24"
        class="table-loading-spinner"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>

    <div class="selector-row flex vcenter">
      <button payee-button (click)="createClient()">Új hitelező</button>
    </div>
  </div>

  <table
    mat-table
    custom-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    matSort
    (matSortChange)="sortData($event)"
    class="mat-elevation-z1"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Hitelező neve</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="tax_number">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        Hitelező adószáma
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.tax_number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="open_cases">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Futó ügyek</th>
      <td mat-cell *matCellDef="let element">
        {{ element.open_cases }}
      </td>
    </ng-container>

    <ng-container matColumnDef="closed_cases">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Lezárt ügyek</th>
      <td mat-cell *matCellDef="let element">
        {{ element.closed_cases }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          class="actions-button"
          payee-button
          width="shorter"
          height="short"
          (click)="navigateToCases(element)"
        >
          Hitelező ügyei
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          class="actions-button"
          payee-button
          width="short"
          height="short"
          (click)="editClient($event, element)"
        >
          Hitelező szerkesztése
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <!-- <button
          [disabled]="element.is_deletable !== true"
          class="actions-button"
          payee-button
          width="short"
          height="short"
          (click)="deleteClient($event, element)"
          ap-tooltip
          [direction]="'top'"
          [tooltipContent]="getIsDeletableMessage(element)"
        >
          Hitelező törlése
        </button> -->
      </td>
    </ng-container>

    <!-- Expanded Content Column -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
      >
        <div class="element-detail alt-theme flex column">
          <div
            *ngIf="element === expandedElement && !!expandedElementData"
            class="client-details"
          >
            <div
              *ngIf="expandedElementData.client.bank_account"
              class="bank-account-details"
            >
              <h1>Bankszámla</h1>
              <span
                >Kedvezményezett:
                {{
                  expandedElementData.client.bank_account.account_holder_name
                }}</span
              >
              <span
                >Bankszámla:
                {{
                  expandedElementData.client.bank_account.account_number
                }}</span
              >
              <span
                >IBAN: {{ expandedElementData.client.bank_account.iban }}</span
              >
              <span
                >BIC: {{ expandedElementData.client.bank_account.bic }}</span
              >
              <span
                >Bank Neve:
                {{ expandedElementData.client.bank_account.bank_name }}</span
              >
            </div>
            <h1>Cím</h1>
            <span>{{ expandedElementData.client.address }}</span>
            <h1>Felhasználók</h1>
            <div *ngFor="let user of expandedElementData.users">
              {{ user.name }} &lt;{{ user.email }}&gt;
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="updateExpandedElement(element)"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="detail-row"
    ></tr>
  </table>

  <mat-paginator
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[1, 5, 10, 25, 100]"
    [length]="length"
    (page)="pageChange($event)"
    disableUnderline
    class="mat-elevation-z1"
  >
  </mat-paginator>
</div>
