import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateUserDialogComponent } from './create-user-dialog.component';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


@NgModule({
  declarations: [
    CreateUserDialogComponent
  ],
  imports: [
    CommonModule,
    PayeePopupModule,
    PayeeFormFieldsModule,
    ReactiveFormsModule,
    PayeeButtonsModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
  ]
})
export class CreateUserDialogModule { }
