import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KsziInfoComponent } from './kszi-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';



@NgModule({
  declarations: [
    KsziInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PayeeFormFieldsModule,
    PayeeButtonsModule,
  ]
})
export class KsziInfoModule { }
