<form id="invoice-resend-form" [formGroup]="formGroup" (submit)="submit()">
  <payee-form-field>
    <span payee-label>Hitelező</span>
    <payee-autocomplete
      [options]="clients"
      [displayWith]="clientDisplayWith"
      (searchChange)="clientSearchSubject.next($event)"
      formControlName="client"
      placeholder="Válassz egyet..."
    ></payee-autocomplete>
  </payee-form-field>

  <payee-form-field>
    <span payee-label>Számla azonosítója</span>
    <input
      payee-input
      formControlName="invoiceNumber"
      placeholder="Pl.: E-LEGAL-2024-01"
    />
  </payee-form-field>

  <button type="submit" payee-button [disabled]="formGroup.invalid || loading">
    Újra küldés
  </button>
</form>

<section *ngIf="!!ksziInfo" id="kszi-info" class="top-margin-30">
  <h1>Kszi adatok</h1>
  <div>Elküldött számlák: {{ ksziInfo.invoices_recieved }}</div>
  <div>Hátralévő számlák: {{ ksziInfo.invoices_pending }}</div>
  <div>Hátralévő idő: {{ ksziInfo.minutes_left }} perc</div>
</section>
