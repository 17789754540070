import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit, OnDestroy {
  userName = '';

  private readonly destroy = new Subject<void>();

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
  ) { }

  ngOnInit(): void {
    this.setUserName();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async logout() {
    await this.authService.logout();
  }

  private setUserName(): void {
    this.usersService.userSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: user => this.userName = user?.name ?? '',
      });
  }
}
