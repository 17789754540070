import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { SzamlazzhuInvoiceGroup } from 'src/app/services/szamlazzhu/types';

@Component({
  selector: 'app-invoice-form-table',
  templateUrl: './invoice-form-table.component.html',
  styleUrls: ['./invoice-form-table.component.scss']
})
export class InvoiceFormTableComponent implements OnInit, OnDestroy {
  readonly dataSource = new MatTableDataSource<SzamlazzhuInvoiceGroup>();
  readonly displayedColumns: string[] = [
    'invoice_number',
    'remaining_amount',
    'due_date_at',
  ];

  @ViewChild(MatSort) sort?: MatSort;
  expandedElement?: SzamlazzhuInvoiceGroup;

  readonly searchControl: FormControl;
  pageSize?: number = 5;
  pageIndex?: number = 0;
  length?: number;
  loading = false;

  private readonly setDataSubject = new Subject<void>();
  private readonly destroy = new Subject<void>();

  private get sortBy() { return this.sort?.active; }
  private get sortDirection() { return this.sort?.direction; }

  constructor(
    private szamlazzhuService: SzamlazzhuService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.searchControl = this.fb.control('');
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    if ('search' in queryParams) {
      this.searchControl.patchValue(queryParams.search);
    }

    this.setDataSubject
      .pipe(
        takeUntil(this.destroy),
        debounceTime(500),
      )
      .subscribe({
        next: async () => await this.setData(),
      });

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe({
        next: async search => {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              search,
            },
            queryParamsHandling: 'merge',
          });
          await this.setData();
        }
      });

    this.setDataSubject.next();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  sortChange() {
    this.pageIndex = 0;
    this.setDataSubject.next();
  }

  pageChange(event: PageEvent) {
    if (event.pageSize !== this.pageSize) {
      this.pageIndex = 0;
    } else {
      this.pageIndex = event.pageIndex;
    }
    this.pageSize = event.pageSize;

    this.setDataSubject.next();
  }

  toggleExpandedElement(element: SzamlazzhuInvoiceGroup) {
    if (this.expandedElement === element) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = element;
    }
  }

  private async setData() {
    if (!this.searchControl.value) {
      return;
    }

    try {
      this.loading = true;

      const result = await this.szamlazzhuService.getInvoiceFormDataTable(
        this.searchControl.value,
        {
          page: this.pageIndex,
          per_page: this.pageSize,
          sort_by: this.sortBy,
          sort_direction: this.sortDirection || null,
        }
      );
      this.dataSource.data = result.invoices;

      this.length = result.total;
    } catch (error) {
      console.error('Error while getting invoices', error);
    } finally {
      this.loading = false;
    }
  }
}
