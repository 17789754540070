import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClientsListComponent } from './clients-list.component';
import { CreateClientDialogComponent } from './create-client-dialog/create-client-dialog.component';
import { PayeeCompanySearchInputFieldModule } from 'src/app/shared/payee-company-search-input-field/payee-company-search-input-field.module';
import { EditClientDialogComponent } from './edit-client-dialog/edit-client-dialog.component';
import { DeleteClientDialogComponent } from './delete-client-dialog/delete-client-dialog.component';



@NgModule({
  declarations: [
    ClientsListComponent,
    CreateClientDialogComponent,
    EditClientDialogComponent,
    DeleteClientDialogComponent,
  ],
  imports: [
    CommonModule,
    PayeeFormFieldsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    PayeeButtonsModule,
    MatSortModule,
    MatDialogModule,
    PayeePopupModule,
    MatSnackBarModule,
    PayeeCompanySearchInputFieldModule,
  ]
})
export class ClientsListModule { }
