import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesTableComponent } from './invoices-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PayeeInfoModule } from 'src/app/shared/payee-info/payee-info.module';



@NgModule({
  declarations: [
    InvoicesTableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    PayeeFormFieldsModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    PayeeInfoModule,
  ]
})
export class InvoicesTableModule { }
