<ng-template #fieldRequiredTemplate>
  <span>A mező kitöltése kötelező!</span>
</ng-template>

<payee-popup
  title="Partner létrehozása"
  [description]="
    !partnerToken
      ? ''
      : 'A Partner Token-t másold ki most, mert késöbb nem lesz látható!'
  "
>
  <form
    *ngIf="!partnerToken"
    class="main-form grow1 hcenter"
    [formGroup]="formGroup"
  >
    <payee-form-field width="wide">
      <input type="text" payee-input formControlName="name" />
      <span payee-label>Partner neve</span>
      <ng-container payee-error *ngIf="name.invalid && name.dirty">
        <ng-template
          *ngIf="name.hasError('required')"
          [ngTemplateOutlet]="fieldRequiredTemplate"
        ></ng-template>
        <span *ngIf="name.hasError('unknown')">
          {{ name.getError("unknown") }}
        </span>
      </ng-container>
    </payee-form-field>

    <payee-form-field width="wide">
      <input type="text" payee-input formControlName="integration" />
      <span payee-label>Partner Azonosítója</span>
      <ng-container
        payee-error
        *ngIf="integration.invalid && integration.dirty"
      >
        <ng-template
          *ngIf="integration.hasError('required')"
          [ngTemplateOutlet]="fieldRequiredTemplate"
        ></ng-template>
        <span *ngIf="integration.hasError('unknown')">
          {{ integration.getError("unknown") }}
        </span>
      </ng-container>
    </payee-form-field>

    <payee-form-field width="wide">
      <input type="text" payee-input formControlName="origin" />
      <span payee-label>Partner Originja</span>
      <ng-container payee-error *ngIf="origin.invalid && origin.dirty">
        <ng-template
          *ngIf="origin.hasError('required')"
          [ngTemplateOutlet]="fieldRequiredTemplate"
        ></ng-template>
        <span *ngIf="origin.hasError('unknown')">
          {{ origin.getError("unknown") }}
        </span>
      </ng-container>
    </payee-form-field>

    <span color="alert-red" *ngIf="formGroup.hasError('unknown')">
      {{ formGroup.getError("unknown") }}
    </span>
  </form>

  <div *ngIf="!!partnerToken">
    <div>Partner neve: {{ name.value }}</div>
    <div>Partner azonosítója: {{ integration.value }}</div>
    <div>Partner originja: {{ origin.value }}</div>
    <input [value]="partnerToken" hidden #partnerTokenInput />
    <div class="flex vcenter">
      <span>Partner Token: {{ partnerToken }}</span>
      <button payee-copy-button [input]="partnerTokenInput"></button>
    </div>
  </div>

  <ng-template #payeePopupSubmitTemplate>
    <button
      *ngIf="!partnerToken"
      payee-button
      width="wide"
      [disabled]="formGroup?.invalid"
      (click)="submit()"
    >
      Mentés
    </button>
    <button
      *ngIf="!!partnerToken"
      payee-button
      width="wide"
      [mat-dialog-close]="true"
    >
      Bezárás
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button
      *ngIf="!partnerToken"
      payee-button
      color="alert-red"
      width="wide"
      [mat-dialog-close]="false"
    >
      Mégse
    </button>
  </ng-template>
</payee-popup>
