import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { GetKsziStatusResponse, SzamlazzhuClientItem } from 'src/app/services/szamlazzhu/types';

@Component({
  selector: 'app-kszi-info',
  templateUrl: './kszi-info.component.html',
  styleUrls: ['./kszi-info.component.scss']
})
export class KsziInfoComponent implements OnInit, OnDestroy {
  readonly client: FormControl;
  readonly kszi_from: FormControl;
  readonly kszi_from_max = new Date(Date.now() - 24 * 60 * 60 * 1000);

  get canEnableKszi(): boolean { return this.kszi_from.valid && this.client.valid; }

  ksziStatus?: GetKsziStatusResponse;

  clients: SzamlazzhuClientItem[] = [];
  readonly setClientSubject = new Subject<string>();

  private readonly destroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private szamlazzhuService: SzamlazzhuService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.client = this.fb.control(null, Validators.required);
    this.kszi_from = this.fb.control(moment().subtract(2, 'years'), [Validators.required, this.dateMax(this.kszi_from_max)]);
  }

  ngOnInit(): void {
    this.setClientSubject
      .pipe(
        takeUntil(this.destroy),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe({
        next: async search => await this.setClients(search),
      });

    this.client.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: async (client?: SzamlazzhuClientItem) => {
          if (!client) {
            return;
          }

          await this.setClient(client.uuid);
        }
      });

    const client_uuid = this.route.snapshot.queryParams.client_uuid;
    if (client_uuid) {
      this.client.patchValue({
        uuid: client_uuid,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  clientDisplayWith(item?: SzamlazzhuClientItem): string {
    return item?.name ?? '';
  }

  async enableKszi(): Promise<void> {
    if (!this.canEnableKszi) {
      return;
    }

    try {
      await this.szamlazzhuService.toggleKszi(this.client.value.uuid, {
        kszi_from: this.kszi_from.value.format('YYYY-MM-DD'),
        switch: 1,
      });

      await this.setClient(this.client.value.uuid);
    } catch (error) {
      console.error('Error while enabling kszi', error);
    }
  }

  private async setClients(search: string): Promise<void> {
    const result = await this.szamlazzhuService.getSzamlazzhuClients({ search });
    this.clients = result.clients;
  }

  private dateMax(max: Date): ValidatorFn {
    return control => {
      const value = control.value as Moment | null;
      if (!value) {
        return null;
      }

      if (value.valueOf() <= max.valueOf()) {
        return null;
      }

      return {
        max: true,
      };
    };
  }

  private async setClient(uuid: string): Promise<void> {
    try {
      this.router.navigate([], {
        queryParams: {
          client_uuid: uuid,
        },
        queryParamsHandling: 'merge',
      });
      this.ksziStatus = await this.szamlazzhuService.getKsziStatus(uuid);

      // If page was refreshed we only know the uuid
      this.client.patchValue({
        name: this.ksziStatus.client.name,
        uuid: this.ksziStatus.client.uuid,
      }, {
        emitEvent: false,
      });
    } catch (error) {
      console.error('Error while getting kszi status', error);
    }
  }
}
