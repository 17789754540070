import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment-timezone';
import { ChartParams, ChartsService, SuccessfulSoftCase } from 'src/app/services/charts/charts.service';

@Component({
  selector: 'app-soft-paid',
  templateUrl: './soft-paid.component.html',
  styleUrls: ['./soft-paid.component.scss']
})
export class SoftPaidComponent implements OnInit {
  filterForm: FormGroup;
  readonly dataSource = new MatTableDataSource<SuccessfulSoftCase>();
  readonly displayedColumns: (keyof SuccessfulSoftCase)[] = [
    'client_name',
    'client_country',
    'client_postcode',
    'client_settlement',
    'client_street',
    'client_tax_number',
    'user_email',
    'debtor_name',
    'client_id',
    'case_id',
    'remaining_amount',
    'original_amount',
    'currency_iso',
    'closed_at',
    'state',
    'product',
    'product_type',
    'partner',
  ];

  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  };

  get from_date(): FormControl { return this.filterForm.get('from_date') as FormControl; }
  get to_date(): FormControl { return this.filterForm.get('to_date') as FormControl; }

  constructor(
    private chartsService: ChartsService,
    private fb: FormBuilder,
  ) {
    this.filterForm = this.fb.group({
      from_date: [moment().subtract(1, 'months').startOf('month'), [Validators.required]],
      to_date: [moment().subtract(1, 'months').endOf('month'), [Validators.required]],
    });
  }

  async ngOnInit() {
    await this.getCharts();
  }

  async getCharts() {
    const params: ChartParams = {
      from_date: this.from_date.value.format('YYYY-MM-DD'),
      to_date: this.to_date.value.format('YYYY-MM-DD'),
    };

    const result = await this.chartsService.getSuccessfulSoftCases(params);
    this.dataSource.data = result.data;
  }
}
