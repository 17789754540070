import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AxiosError } from 'axios';
import { PartnersDataTableItem, PartnerService } from 'src/app/services/partner/partner.service';

@Component({
  selector: 'app-edit-partner-dialog',
  templateUrl: './edit-partner-dialog.component.html',
  styleUrls: ['./edit-partner-dialog.component.scss']
})
export class EditPartnerDialogComponent {
  readonly formGroup: FormGroup;

  private loading = false;

  partnerToken?: string;

  get name(): FormControl { return this.formGroup.get('name') as FormControl; };
  get change_partner_token(): FormControl { return this.formGroup.get('change_partner_token') as FormControl; };

  constructor(
    private fb: FormBuilder,
    private partnerService: PartnerService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public readonly data: PartnersDataTableItem,
    private dialogRef: MatDialogRef<EditPartnerDialogComponent>,
  ) {
    this.formGroup = this.fb.group({
      name: [data.name, Validators.required],
      change_partner_token: false,
    });
  }

  async submit() {
    if (this.formGroup.invalid || this.loading) {
      this.name.markAsDirty();

      return;
    }

    try {
      const { partner } = await this.partnerService.editPartner(this.data.id, this.formGroup.value);
      if (!this.change_partner_token.value) {
        this.dialogRef.close(true);
        return;
      }

      this.partnerToken = partner.api_key;
    } catch (error) {
      this.name.markAsDirty();

      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          const errors: undefined | Record<string, string[]> = error.response?.data?.errors;
          if (errors) {
            Object.entries(errors).forEach(([key, errors]) => {
              const control = this.formGroup.get(key);
              if (control) {
                control.setErrors({
                  unknown: errors[0],
                });
              } else {
                this.formGroup.setErrors({
                  unknown: errors[0],
                });
              }
            });
            return;
          }
        }
      }
      this.snackbar.open('Ismeretlen hiba!', 'OK', { duration: 5000 });
      console.error('Unknown error while creating partner!', error);
    }
  }
}
