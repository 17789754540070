import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import axios from 'axios';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-airtable-email-test',
  templateUrl: './airtable-email-test.component.html',
  styleUrls: ['./airtable-email-test.component.scss']
})
export class AirtableEmailTestComponent {
  readonly formGroup: FormGroup;

  get userName(): string { return this.usersService.userSubject.value?.name ?? ''; }
  get userEmail(): string { return this.usersService.userSubject.value?.email ?? ''; }

  get template(): FormControl { return this.formGroup.get('template') as FormControl; }
  get payee_case_id(): FormControl { return this.formGroup.get('payee_case_id') as FormControl; }

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
  ) {
    this.formGroup = this.fb.group({
      template: ['', Validators.required],
      payee_case_id: ['P1001', Validators.required],
    });
  }

  async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    try {
      await axios.post(environment.baseUrl + '/api/test-email/template', {
        payee_case_reference_id: this.payee_case_id.value,
        template: this.template.value,
      });

      this.snackbar.open('Email kiküldve!', 'OK', {
        duration: 5000,
      });
    } catch (error) {
      console.error('Error while sending airtable test email!', error);
      this.snackbar.open('Valami hiba történt!', 'OK', {
        duration: 5000,
      });
    }
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
