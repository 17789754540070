import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDiscountsComponent } from './product-discounts.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { EditProductDiscountDialogComponent } from './edit-product-discount-dialog/edit-product-discount-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DeleteProductDiscountDialogComponent } from './delete-product-discount-dialog/delete-product-discount-dialog.component';
import { PayeeInputModule } from 'src/app/shared/payee-input/payee-input.module';
import { CreateProductDiscountDialogComponent } from './create-product-discount-dialog/create-product-discount-dialog.component';


@NgModule({
  declarations: [
    ProductDiscountsComponent,
    EditProductDiscountDialogComponent,
    DeleteProductDiscountDialogComponent,
    CreateProductDiscountDialogComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    PayeeButtonsModule,
    MatTableModule,
    MatDialogModule,
    PayeePopupModule,
    ReactiveFormsModule,
    PayeeFormFieldsModule,
    PayeeIconModule,
    MatPaginatorModule,
  ]
})
export class ProductDiscountsModule { }
