import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import axios from 'axios';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';

export type TemplateOption = {
  class: string;
  language: string;
  subject: string;
};

@Component({
  selector: 'app-workflow-email-test',
  templateUrl: './workflow-email-test.component.html',
  styleUrls: ['./workflow-email-test.component.scss']
})
export class WorkflowEmailTestComponent implements OnInit {
  readonly formGroup: FormGroup;
  templateOptions: TemplateOption[] = [];

  get userName(): string { return this.usersService.userSubject.value?.name ?? ''; }
  get userEmail(): string { return this.usersService.userSubject.value?.email ?? ''; }

  get templates(): FormArray { return this.formGroup.get('templates') as FormArray; }
  get payee_case_id(): FormControl { return this.formGroup.get('payee_case_id') as FormControl; }

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
  ) {
    this.formGroup = this.fb.group({
      templates: this.fb.array([], Validators.required),
      payee_case_id: ['P1001', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    const result = await axios.get<TemplateOption[]>(environment.baseUrl + '/api/test-email/workflow-templates');
    this.templateOptions = result.data;
    this.templateOptions
      .filter(t => /.*ASoftEmailTov2.*Debtor$/.test(t.class))
      .forEach(t => this.templates.push(this.fb.control(t)));
  }

  getIndexOfTemplate(t: TemplateOption): number {
    return this.templates.value.findIndex((selected: TemplateOption) => t.class === selected.class);
  }

  togglaAll(): void {
    if (this.templates.length !== this.templateOptions.length) {
      this.templateOptions.forEach(t => {
        const index = this.getIndexOfTemplate(t);
        if (index === -1) {
          this.templates.push(this.fb.control(t));
        }
      });
    } else {
      this.templates.clear();
    }
  }

  onTemplateChanged(event: MatCheckboxChange, t: TemplateOption) {
    const { checked } = event;
    const index = this.getIndexOfTemplate(t);
    if (index === -1 && checked) {
      this.templates.push(this.fb.control(t));
    } else if (index !== -1 && !checked) {
      this.templates.removeAt(index);
    }
  }

  async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    try {
      await axios.post(environment.baseUrl + '/api/test-email/workflow-templates', {
        payee_case_reference_id: this.payee_case_id.value,
        templates: this.templates.value.map((t: TemplateOption) => t.class),
      });

      this.snackbar.open('Emailek kiküldve!', 'OK', {
        duration: 5000,
      });
    } catch (error) {
      console.error('Error while sending workflow test emails!', error);
      this.snackbar.open('Valami hiba történt!', 'OK', {
        duration: 5000,
      });
    }
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
