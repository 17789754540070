import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { ClientsService, ListClientsParams } from 'src/app/services/clients/clients.service';
import { FeesService, ListFeesParams } from 'src/app/services/fees/fees.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ListPartnersParams, PartnerService } from 'src/app/services/partner/partner.service';
import { ProductDiscount, ProductDiscountService } from 'src/app/services/product-discount/product-discount.service';
import { ProductService } from 'src/app/services/product-service/product-service.service';

@Component({
  selector: 'app-edit-product-discount-dialog',
  templateUrl: './edit-product-discount-dialog.component.html',
  styleUrls: ['./edit-product-discount-dialog.component.scss']
})
export class EditProductDiscountDialogComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;
  submitLoading = false;

  readonly productDiscountableTypeOptions: ProductDiscount['product_discountable']['type'][] = [
    'client',
    'partner',
  ];
  productDiscountableOptions: ProductDiscount['product_discountable'][] = [];
  readonly setProductDiscountableOptionsSubject = new Subject<string>();
  productDiscountableOptionsLoading = false;

  readonly discountTypeOptions: ProductDiscount['discount_type'][] = [
    'amount',
    'percentage',
  ];


  feeOptions: ProductDiscount['fee'][] = [];
  readonly setFeeOptionsSubject = new Subject<string>();
  feeOptionsLoading = false;

  productOptions: ProductDiscount['product'][] = [];

  private readonly destroy = new Subject<void>();

  get product_discountable_type(): FormControl { return this.formGroup.get('product_discountable_type') as FormControl; }
  get product_discountable(): FormControl { return this.formGroup.get('product_discountable') as FormControl; }
  get discount(): FormControl { return this.formGroup.get('discount') as FormControl; }
  get discount_type(): FormControl { return this.formGroup.get('discount_type') as FormControl; }
  get fee(): FormControl { return this.formGroup.get('fee') as FormControl; }
  get product(): FormControl { return this.formGroup.get('product') as FormControl; }

  constructor(
    private fb: FormBuilder,
    private ref: MatDialogRef<EditProductDiscountDialogComponent>,
    private clientsService: ClientsService,
    private partnerService: PartnerService,
    private productService: ProductService,
    private productDiscountService: ProductDiscountService,
    private feesService: FeesService,
    private helpersService: HelpersService,
    @Inject(MAT_DIALOG_DATA) public productDiscount: ProductDiscount,
  ) {
    this.formGroup = this.fb.group({
      product_discountable_type: [this.productDiscount.product_discountable.type, Validators.required],
      product_discountable: [this.productDiscount.product_discountable, Validators.required],
      discount: [this.productDiscount.discount, [Validators.required, Validators.min(0)]],
      discount_type: [this.productDiscount.discount_type, [Validators.required]],
      fee: [this.productDiscount.fee, Validators.required],
      product: [this.productDiscount.product, Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.product_discountable_type.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: () => {
          this.productDiscountableOptions = [];
          this.product_discountable.patchValue(null);
        },
      });

    this.product.valueChanges
      .pipe(
        takeUntil(this.destroy),
        distinctUntilChanged(),
      )
      .subscribe({
        next: () => {
          this.fee.patchValue(null);
          this.setFeeOptionsSubject.next();
        }
      });

    this.setProductDiscountableOptionsSubject
      .pipe(
        takeUntil(this.destroy),
        filter(v => !v || v.length >= 3),
        debounceTime(500),
        map(filter => ({ filter, type: this.product_discountable_type.value })),
        distinctUntilChanged((x, y) => x.filter === y.filter && x.type === y.type),
        map(v => v.filter),
      )
      .subscribe({
        next: async filter => await this.setProductDiscountableOptions(filter)
      });

    this.setFeeOptionsSubject
      .pipe(
        takeUntil(this.destroy),
        filter(v => !v || v.length >= 3),
        debounceTime(500),
        map(filter => ({ filter, product: this.product.value })),
        distinctUntilChanged((x, y) => x.filter === y.filter && x.product === y.product),
        map(v => v.filter),
      )
      .subscribe({
        next: async filter => await this.setFeeOptions(filter)
      });

    await this.setProductOptions();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  readonly productDiscountableDisplayWith = (discountable?: ProductDiscount['product_discountable']): string => {
    return discountable?.name ?? '';
  };

  readonly productDisplayWith = (product?: ProductDiscount['product']): string => {
    return product?.name ?? '';
  };

  readonly feeDisplayWith = (fee?: ProductDiscount['fee']): string => {
    return fee?.label ?? '';
  };

  async submit(): Promise<void> {
    if (this.submitLoading) {
      return;
    }
    if (this.formGroup.invalid) {
      this.helpersService.markAllChildrenAsDirty(this.formGroup);
      return;
    }

    try {
      this.submitLoading = true;

      const response = await this.productDiscountService.updateProductDiscount(
        this.productDiscount.id,
        {
          discount: this.discount.value,
          discount_type: this.discount_type.value,
          fee_id: this.fee.value.id,
          product_discountable_id: this.product_discountable.value.id,
          product_discountable_type: this.product_discountable_type.value,
        },
      );

      this.ref.close(response);
    } catch (error) {
      console.error('Error while saving product discount', error);
    } finally {
      this.submitLoading = false;
    }
  }

  cancel(): void {
    this.ref.close();
  }

  private async setProductDiscountableOptions(filter?: string) {
    const loadTimeout = setTimeout(() => this.productDiscountableOptionsLoading = true, 200);
    try {
      if (this.product_discountable_type.value === 'client') {
        const params: ListClientsParams = {};
        if (filter) {
          params.name = filter;
        }
        const result = await this.clientsService.listClients(params);
        this.productDiscountableOptions = result.clients.map(c => ({
          ...c,
          type: 'client',
        }));
      } else {
        const params: ListPartnersParams = {};
        if (filter) {
          params.name = filter;
        }
        const result = await this.partnerService.listPartners(params);
        this.productDiscountableOptions = result.partners.map(p => ({
          ...p,
          type: 'partner',
        }));
      }
    } catch (error) {
      console.error('Error while getting product discountables', {
        type: this.product_discountable_type.value,
        error,
      });
    } finally {
      clearTimeout(loadTimeout);
      this.productDiscountableOptionsLoading = false;
    }
  }

  private async setProductOptions(): Promise<void> {
    try {
      const response = await this.productService.getProducts();
      this.productOptions = response.products.map(p => ({
        currency_iso: p.currency,
        id: p.id,
        name: p.name,
        type: p.type,
      }));
    } catch (error) {
      console.error('Error while getting products');
    }
  }

  private async setFeeOptions(filter?: string): Promise<void> {
    const loadTimeout = setTimeout(() => this.feeOptionsLoading = true, 200);
    try {
      const params: ListFeesParams = {
        product_id: this.product.value.id,
      };
      if (filter) {
        params.label = filter;
      }
      const result = await this.feesService.listFees(params);
      this.feeOptions = result.fees;
    } catch (error) {
      console.error('Error while getting fees', error);
    } finally {
      clearTimeout(loadTimeout);
      this.feeOptionsLoading = false;
    }
  }
}
