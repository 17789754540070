<header class="flex vcenter">
  <button payee-button width="shorter" routerLink="case-starts">Ügyindítások</button>
  <button payee-button routerLink="soft-paid">Sikeres Fizetési Felszólítások</button>
  <div class="profile-wrapper flex vcenter">
    <button payee-icon-button class="logout-button" (click)="logout()">
      <payee-icon name="logout-grey"></payee-icon>
    </button>
    <button payee-button color="grey-light" class="name-button">
      {{ userName }}
    </button>
  </div>
</header>

<router-outlet></router-outlet>