import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductDiscount, ProductDiscountService } from 'src/app/services/product-discount/product-discount.service';

@Component({
  selector: 'app-delete-product-discount-dialog',
  templateUrl: './delete-product-discount-dialog.component.html',
  styleUrls: ['./delete-product-discount-dialog.component.scss']
})
export class DeleteProductDiscountDialogComponent {
  submitLoading = false;

  constructor(
    private ref: MatDialogRef<DeleteProductDiscountDialogComponent>,
    private productDiscountService: ProductDiscountService,
    @Inject(MAT_DIALOG_DATA) public productDiscount: ProductDiscount,
  ) { }

  async submit(): Promise<void> {
    if (this.submitLoading) {
      return;
    }

    try {
      this.submitLoading = true;

      await this.productDiscountService.deleteProductDiscount(this.productDiscount.id);

      this.ref.close(true);
    } catch (error) {
      console.error('Error while saving product discount', error);
    } finally {
      this.submitLoading = false;
    }
  }

  cancel(): void {
    this.ref.close();
  }
}
