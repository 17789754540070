<div class="flex vcenter">
  <payee-form-field>
    <input
      type="text"
      [formControl]="searchControl"
      payee-input
      placeholder="Ügy iktatószáma"
    />
  </payee-form-field>
  <mat-progress-spinner
    mode="indeterminate"
    diameter="24"
    *ngIf="loading"
  ></mat-progress-spinner>
</div>

<table
  mat-table
  multiTemplateDataRows
  [dataSource]="dataSource"
  matSort
  (matSortChange)="sortChange()"
  [matSortDisabled]="loading"
  class="mat-elevation-z1 top-margin-15"
  id="invoice-table"
>
  <ng-container matColumnDef="invoice_number">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Számla Azonosító</th>
    <td mat-cell *matCellDef="let element">
      {{ element.invoice.invoice_number }}
    </td>
  </ng-container>

  <ng-container matColumnDef="remaining_amount">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Számla Összege</th>
    <td mat-cell *matCellDef="let element">
      <span>
        {{ element.invoice.remaining_amount | number : "1.0" : "hu" }}&nbsp;{{
          element.invoice.currency_iso
        }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="due_date_at">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Fizetési Határidő</th>
    <td mat-cell *matCellDef="let element">
      {{ element.invoice.due_date_at | date : "yyyy.MM.dd." }}
    </td>
  </ng-container>

  <!-- Expanded Content Column -->
  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <ng-container *ngIf="expandedElement === element">
        <div class="detail-column">
          <section class="main-invoice">
            <h1>Főszámla</h1>
            <div class="invoice-data-entry-row">
              <div class="invoice-data-title">Azonosító:</div>
              <div class="invoice-data-value">
                {{ element.invoice.invoice_number }}
              </div>
            </div>
            <div class="invoice-data-entry-row">
              <div class="invoice-data-title">Számla Összege:</div>
              <div class="invoice-data-value">
                {{ element.invoice.remaining_amount | number : "1.0" : "hu" }}
                {{ element.invoice.currency_iso }}
              </div>
            </div>
            <div class="invoice-data-entry-row">
              <div class="invoice-data-title">Fizetési Határidő:</div>
              <div class="invoice-data-value">
                {{ element.invoice.due_date_at | date : "yyyy.MM.dd." }}
              </div>
            </div>
            <div class="invoice-data-entry-row">
              <div class="invoice-data-title">Kelte:</div>
              <div class="invoice-data-value">
                {{ element.invoice.issued_at | date : "yyyy.MM.dd." }}
              </div>
            </div>
          </section>
          <section *ngIf="element.corrections.length > 0" class="correction-invoices">
            <h1>Helyesbítő számlák</h1>
            <section
              *ngFor="let correction of element.corrections; let i=index"
              class="correction-invoice"
            >
            <h2>{{ i + 1 }}. Helyesbítő számla</h2>
            <div class="invoice-data-entry-row">
              <div class="invoice-data-title">Azonosító:</div>
              <div class="invoice-data-value">
                {{ correction.invoice_number }}
              </div>
            </div>
            <div class="invoice-data-entry-row">
              <div class="invoice-data-title">Számla Összege:</div>
              <div class="invoice-data-value">
                {{ correction.remaining_amount | number : "1.0" : "hu" }}
                {{ correction.currency_iso }}
              </div>
            </div>
            <div class="invoice-data-entry-row">
              <div class="invoice-data-title">Fizetési Határidő:</div>
              <div class="invoice-data-value">
                {{ correction.due_date_at | date : "yyyy.MM.dd." }}
              </div>
            </div>
            <div class="invoice-data-entry-row">
              <div class="invoice-data-title">Kelte:</div>
              <div class="invoice-data-value">
                {{ correction.issued_at | date : "yyyy.MM.dd." }}
              </div>
            </div>
            </section>
          </section>
        </div>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    (click)="toggleExpandedElement(element)"
    class="element-row"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    [class.expanded-row]="expandedElement === row"
    class="detail-row"
  ></tr>
</table>

<mat-paginator
  [disabled]="loading"
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [length]="length"
  showFirstLastButtons
  (page)="pageChange($event)"
  class="mat-elevation-z1"
></mat-paginator>
