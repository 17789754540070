import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-get-password-link-success',
  templateUrl: './get-password-link-success.component.html',
  styleUrls: ['./get-password-link-success.component.scss']
})
export class GetPasswordLinkSuccessComponent {
  loading = false;
  emailSent = false;

  readonly email: string;

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
  ) {
    this.titleService.setTitle('Új jelszó kérése - Payee');

    this.email = this.route.snapshot.queryParams.email;

    if (!this.email) {
      this.router.navigateByUrl('/login');
    }
  }

  async resendForgotPasswordEmail() {
    if (this.loading || this.emailSent) {
      return;
    }

    try {
      this.loading = true;
      this.emailSent = true;
      await this.authService.sendPasswordResetEmail({
        email: this.email,
      });
      this.snackbar.open('Email elküldve!', '', {
        duration: 15000,
      });
    } finally {
      this.loading = false;
      setTimeout(() => this.emailSent = false, 15000);
    }
  }
}
