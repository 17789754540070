import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartyType } from 'src/types';
import { ClientDataTableItem, ClientsService } from 'src/app/services/clients/clients.service';
import { EnumsService } from 'src/app/services/enums/enums.service';
import { partyType } from 'src/app/components/dashboard/enums/enum-descriptors';
import { Subject } from 'rxjs';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { CompanySearchAutocompleteItem } from 'src/app/shared/company-search-service/company-search.service';
import { AxiosError } from 'axios';

@Component({
  selector: 'app-edit-client-dialog',
  templateUrl: './edit-client-dialog.component.html',
  styleUrls: ['./edit-client-dialog.component.scss']
})
export class EditClientDialogComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;

  submitLoading = false;
  initLoading = false;

  private partyTypes: PartyType[] = [];
  partyTypeOptions: number[] = [];

  private readonly destroy = new Subject<void>();

  type: 'ind' | 'org';
  get address(): FormGroup { return this.formGroup.get('address') as FormGroup; }
  get country(): FormControl { return this.address.get('country') as FormControl; }
  get settlement(): FormControl { return this.address.get('settlement') as FormControl; }
  get postcode(): FormControl { return this.address.get('postcode') as FormControl; }
  get street(): FormControl { return this.address.get('street') as FormControl; }
  get tax_number(): FormControl { return this.formGroup.get('tax_number') as FormControl; }
  get name(): FormControl { return this.formGroup.get('name') as FormControl; }
  get party_type_id(): FormControl { return this.formGroup.get('party_type_id') as FormControl; }

  constructor(
    public dialogRef: MatDialogRef<EditClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private client: ClientDataTableItem,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private clientsService: ClientsService,
    private enumsService: EnumsService,
    private helpersService: HelpersService,
  ) {
    this.formGroup = this.fb.group({
      tax_number: [{ value: this.client.tax_number, disabled: true }, Validators.required],
      name: [{ value: this.client.name, disabled: true }, Validators.required],
      party_type_id: [this.client.party_type_id, Validators.required],
      address: this.fb.group({
        country: [{ value: 'Magyarország', disabled: true }],
        settlement: [this.client.address.settlement, Validators.required],
        postcode: [this.client.address.postcode, Validators.required],
        street: [this.client.address.street, Validators.required],
      }),
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      this.initLoading = true;

      const [
        partyTypes,
      ] = await Promise.all([
        this.enumsService.getEnum<PartyType>(partyType.model),
      ]);

      this.partyTypes = partyTypes;

      const clientType = this.partyTypes.find(pt => pt.id === this.client.party_type_id);
      this.type = clientType?.type ?? 'org';
      if (this.type === 'org') {
        this.address.disable();
      }
      this.partyTypeOptions = partyTypes.filter(pt => pt.type === this.type).map(pt => pt.id);
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt a dialog megnyitásakor', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
    } finally {
      this.initLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onCompanySelected(item: CompanySearchAutocompleteItem) {
    this.tax_number.patchValue(item.tax_number);
    this.party_type_id.patchValue(+item.party_type_id);
  }

  async submit() {
    if (this.formGroup.invalid || this.submitLoading) {
      return;
    }

    try {
      this.submitLoading = true;

      let result: ClientDataTableItem;
      if (this.type === 'ind') {
        result = await this.clientsService.updateClient(this.client.id, {
          name: this.name.value,
          address: {
            country_id: this.country.value,
            postcode: this.postcode.value,
            settlement: this.settlement.value,
            street: this.street.value
          },
          party_type_id: this.party_type_id.value,
        });
      } else {
        result = await this.clientsService.updateClient(this.client.id, {
          party_type_id: this.party_type_id.value,
        });
      }
      Object.assign(this.client, result);

      this.dialogRef.close(this.name.value);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          this.helpersService.markAllChildrenAsDirty(this.formGroup);
          const errors: Record<string, string[]> = error.response.data.errors;
          Object.entries(errors).forEach(([key, values]) => {
            const formControl = this.formGroup.get(key);
            if (formControl) {
              formControl.setErrors({
                backend: values[0],
              });
            } else {
              this.formGroup.setErrors({
                backend: values[0],
              });
            }
          });
          return;
        }
      }
      console.error('Error while updating client', error);
      this.snackbar.open('Valami hiba történt mentéskor!', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
    } finally {
      this.submitLoading = false;
    }
  }

  readonly partyTypeDisplayWith = (id: number): string => {
    return this.partyTypes.find(t => t.id === id)?.label || '';
  };
}
