import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ProductDiscount, ProductDiscountService } from 'src/app/services/product-discount/product-discount.service';
import { EditProductDiscountDialogComponent } from './edit-product-discount-dialog/edit-product-discount-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { DeleteProductDiscountDialogComponent } from './delete-product-discount-dialog/delete-product-discount-dialog.component';
import { CreateProductDiscountDialogComponent } from './create-product-discount-dialog/create-product-discount-dialog.component';

@Component({
  selector: 'app-product-discounts',
  templateUrl: './product-discounts.component.html',
  styleUrls: ['./product-discounts.component.scss']
})
export class ProductDiscountsComponent implements OnInit, OnDestroy {
  get userName(): string { return this.usersService.userSubject.value?.name ?? ''; }
  readonly dataSource = new MatTableDataSource<ProductDiscount>();
  readonly displayedColumns = [
    'product',
    'fee',
    'product_discountable_type',
    'product_discountable_name',
    'discount',
    'edit',
    'delete',
  ];
  loading = false;

  pageSize: number = 10;
  pageIndex: number = 0;
  length: number;

  private readonly destroy = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private productDiscountService: ProductDiscountService,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.setDataSource();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  createProductDiscount(): void {
    const ref = this.dialog.open(CreateProductDiscountDialogComponent);
    ref.afterClosed()
      .pipe(first(), takeUntil(this.destroy))
      .subscribe({
        next: async (result?: ProductDiscount) => {
          if (result) {
            this.dataSource.data = [result];
          }
        },
      });
  }

  editProductDiscount(product: ProductDiscount): void {
    const ref = this.dialog.open(EditProductDiscountDialogComponent, {
      data: product,
    });
    ref.afterClosed()
      .pipe(first(), takeUntil(this.destroy))
      .subscribe({
        next: async (result?: ProductDiscount) => {
          if (result) {
            Object.assign(product, result);
          }
        },
      });
  }

  deleteProductDiscount(product: ProductDiscount): void {
    const ref = this.dialog.open(DeleteProductDiscountDialogComponent, {
      data: product,
    });
    ref.afterClosed()
      .pipe(first(), takeUntil(this.destroy))
      .subscribe({
        next: async (result?: ProductDiscount) => {
          if (result) {
            await this.setDataSource();
          }
        },
      });
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }

  async pageChange(event: PageEvent): Promise<void> {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    await this.setDataSource();
  }

  private async setDataSource(): Promise<void> {
    try {
      this.loading = true;
      const result = await this.productDiscountService.getProductDiscounts({
        page: this.pageIndex + 1,
        per_page: this.pageSize,
      });
      this.length = result.pagination.total;
      this.dataSource.data = result.data;
    } catch (error) {
      console.error('Error while loading product discounts', error);
    } finally {
      this.loading = false;
    }
  }
}
