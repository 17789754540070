<payee-form-field>
  <payee-autocomplete
    [options]="clients"
    [displayWith]="clientDisplayWith"
    (searchChange)="setClientSubject.next($event)"
    [formControl]="client"
  ></payee-autocomplete>
</payee-form-field>

<section id="kszi-info" *ngIf="!!ksziStatus">
  <div id="client-info" class="top-margin-15">
    <h1>Hitelező</h1>
    <div>
      <span class="data-title">Név:</span>
      <span class="data-value">{{ ksziStatus.client.name }}</span>
    </div>
    <div>
      <span class="data-title">Számlák elküldve:</span>
      <span class="data-value">
        <span>{{ ksziStatus.client.kszi_info.invoices_pending }}</span>
        <span color="grey-dark">&nbsp;/&nbsp;{{ ksziStatus.client.kszi_info.invoices_recieved }}</span>
      </span>
    </div>
    <div *ngIf="ksziStatus.client.kszi_info.minutes_left > 0">
      <span class="data-title">Várható időtartam:</span>
      <span class="data-value">
        <span>{{ ksziStatus.client.kszi_info.minutes_left }} perc</span>
      </span>
    </div>
    <div>
    </div>
    <div>
      <span class="data-title">Név:</span>
      <span class="data-value">{{ ksziStatus.client.name }}</span>
    </div>
    <div *ngIf="ksziStatus.client.kszi_enabled">
      <span class="data-title">Számlák küldése ettől a dátumtól:</span>
      <span class="data-value">{{
        ksziStatus.client.kszi_from | date : "yyyy.MM.dd."
      }}</span>
    </div>
    <div *ngIf="!ksziStatus.client.kszi_enabled" id="enable-kszi" class="flex">
      <payee-form-field>
        <span payee-label>Számlák küldése ettől a dátumtól:</span>
        <payee-datepicker
          [max]="kszi_from_max"
          [formControl]="kszi_from"
        ></payee-datepicker>
      </payee-form-field>
      <button
        [disabled]="!canEnableKszi"
        payee-button
        width="shorter"
        (click)="enableKszi()"
      >
        Bekapcsolás
      </button>
    </div>
  </div>
  <div id="users-info" class="top-margin-15">
    <h1>Számlázz.hu-s Felhasználók</h1>
    <table id="users-table">
      <tr>
        <th>Név</th>
        <th>Email</th>
      </tr>
      <tr *ngFor="let user of ksziStatus.users">
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
      </tr>
    </table>
  </div>
</section>
