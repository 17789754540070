<header class="flex vcenter">
  <button color="grey-light" payee-icon-button (click)="logout()">
    <payee-icon name="logout-grey"></payee-icon>
  </button>
  <button payee-button color="grey-light" class="name-button">
    {{ userName }}
  </button>
</header>

<main>
  <h1>Teszt email küldése</h1>
  <h2 class="top-margin-15">
    Erre az email címre fog kimenni az email: {{ userEmail }}
  </h2>
  <form [formGroup]="formGroup" class="top-margin-30">
    <payee-info width="widest">
      <div>Bármelyik létező ügy azonosítója, pl. P1001.</div>
      <div>Ennek az ügynek az adataival fog az email feltöltődni.</div>
    </payee-info>
    <payee-form-field width="widest" color="white" class="top-margin-5">
      <span payee-label color="black">Ügyazonosító</span>
      <input payee-input formControlName="payee_case_id" />
    </payee-form-field>
    <payee-info width="widest" class="top-margin-15">
      <div>Template airtable-ből.</div>
      <div>Kiküldés előtti html encode-olja, nem kell unescape-elni.</div>
    </payee-info>
    <payee-form-field width="widest" color="white" class="top-margin-5">
      <span payee-label color="black">Template</span>
      <textarea payee-input formControlName="template"></textarea>
    </payee-form-field>
    <button payee-button (click)="submit()">Küldés!</button>
  </form>
</main>
