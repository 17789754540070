import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnersListComponent } from './partners-list.component';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatSortModule } from '@angular/material/sort';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { CreatePartnerDialogComponent } from './create-partner-dialog/create-partner-dialog.component';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { MatDialogModule } from '@angular/material/dialog';
import { EditPartnerDialogComponent } from './edit-partner-dialog/edit-partner-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';



@NgModule({
  declarations: [
    PartnersListComponent,
    CreatePartnerDialogComponent,
    EditPartnerDialogComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    PayeeButtonsModule,
    MatSortModule,
    PayeeIconModule,
    PayeeFormFieldsModule,
    PayeePopupModule,
    MatDialogModule,
    MatCheckboxModule,
  ],
})
export class PartnersListModule { }
