import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type ListPartnersParams = {
  name?: string;
};

export type ListPartnersResponse = {
  partners: {
    id: string;
    name: string;
  }[];
};

export type PartnersDataTableItem = {
  id: number;
  integration: string;
  name: string;
  api_key: string;
  origin: string;
  created_at: string;
};

export type PartnersDataTableResponse = {
  data: PartnersDataTableItem[];
  headers: never[];
  pagination: null;
  sortBy: never[];
};

export type CreatePartnerParams = {
  name: string;
  integration: string;
  origin: string;
};

export type CreatePartnerResponse = {
  partner: {
    id: number;
    api_key: string;
    name: string;
    integration: string;
    origin: string;
    created_at: string;
    updated_at: string | null;
  };
};

export type EditPartnerParams = {
  name: string;
  change_partner_token: boolean;
};
export type EditPartnerResponse = CreatePartnerResponse;

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  async createPartner(params: CreatePartnerParams): Promise<CreatePartnerResponse> {
    const url = `${environment.baseUrl}/api/partner`;
    const result = await axios.post<CreatePartnerResponse>(url, params);
    return result.data;
  }

  async editPartner(partnerId: number, params: EditPartnerParams): Promise<EditPartnerResponse> {
    const url = `${environment.baseUrl}/api/partner/${partnerId}`;
    const result = await axios.post<EditPartnerResponse>(url, {
      ...params,
      '_method': 'PUT',
    });
    return result.data;
  }

  async listPartners(params: ListPartnersParams): Promise<ListPartnersResponse> {
    const url = `${environment.baseUrl}/api/partner`;
    const result = await axios.get<ListPartnersResponse>(url, { params });
    return result.data;
  }

  async getPartnersDataTable(): Promise<PartnersDataTableResponse> {
    const url = `${environment.baseUrl}/api/partner-datatable`;
    const result = await axios.get<PartnersDataTableResponse>(url);
    return result.data;
  }
}
