import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { PayeePopupModule } from "src/app/shared/payee-popup/payee-popup.module";
import { PayeeButtonsModule } from "../payee-buttons/payee-buttons.module";
import { GeneralSuccessDialogComponent } from "./general-success-dialog.component";
import { PayeeIconModule } from "../payee-icon/payee-icon.module";

@NgModule({
  declarations: [
    GeneralSuccessDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    PayeeButtonsModule,
    PayeePopupModule,
    PayeeIconModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GeneralSuccessDialogModule { }