import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AxiosError } from 'axios';
import { remove } from 'remove-accents';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PartnerService } from 'src/app/services/partner/partner.service';

@Component({
  selector: 'app-create-partner-dialog',
  templateUrl: './create-partner-dialog.component.html',
  styleUrls: ['./create-partner-dialog.component.scss']
})
export class CreatePartnerDialogComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;

  private loading = false;
  private readonly destroy = new Subject<void>();

  partnerToken?: string;

  get name(): FormControl { return this.formGroup.get('name') as FormControl; };
  get integration(): FormControl { return this.formGroup.get('integration') as FormControl; };
  get origin(): FormControl { return this.formGroup.get('origin') as FormControl; };

  constructor(
    private fb: FormBuilder,
    private partnerService: PartnerService,
    private snackbar: MatSnackBar,
  ) {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      integration: ['', Validators.required],
      origin: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.name.valueChanges
      .pipe(
        takeUntil(this.destroy),
        distinctUntilChanged(),
      )
      .subscribe({
        next: (name: string) => {
          const noAccents = remove(name);

          this.integration.patchValue(
            noAccents
              .toLowerCase()
              .trim()
              .replace(/[.]/g, '')
              .replace(/[-]/g, '_')
              .replace(/\s+/g, '-'),
          );

          this.origin.patchValue(
            'PLUGIN_' +
            noAccents
              .toUpperCase()
              .trim()
              .replace(/[.]/g, '')
              .replace(/[-]/g, '_')
              .replace(/\s+/g, '-'),
          );
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async submit() {
    if (this.formGroup.invalid || this.loading) {
      this.name.markAsDirty();
      this.integration.markAsDirty();
      this.origin.markAsDirty();

      return;
    }

    try {
      const { partner } = await this.partnerService.createPartner(this.formGroup.value);

      this.partnerToken = partner.api_key;
    } catch (error) {
      this.name.markAsDirty();
      this.integration.markAsDirty();
      this.origin.markAsDirty();

      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          const errors: undefined | Record<string, string[]> = error.response?.data?.errors;
          if (errors) {
            Object.entries(errors).forEach(([key, errors]) => {
              const control = this.formGroup.get(key);
              if (control) {
                control.setErrors({
                  unknown: errors[0],
                });
              } else {
                this.formGroup.setErrors({
                  unknown: errors[0],
                });
              }
            });
            return;
          }
        }
      }
      this.snackbar.open('Ismeretlen hiba!', 'OK', { duration: 5000 });
      console.error('Unknown error while creating partner!', error);
    }
  }
}
