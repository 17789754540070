<table
  mat-table
  multiTemplateDataRows
  [dataSource]="dataSource"
  class="mat-elevation-z1 top-margin-15"
  id="failed-jobs-table"
>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Művelet Neve</th>
    <td mat-cell *matCellDef="let element">
      {{ element.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="tags">
    <th mat-header-cell *matHeaderCellDef>Címkék</th>
    <td mat-cell *matCellDef="let element">
      {{ element.tags.join(", ") }}
    </td>
  </ng-container>

  <ng-container matColumnDef="queue">
    <th mat-header-cell *matHeaderCellDef>Queue</th>
    <td mat-cell *matCellDef="let element">
      {{ element.queue }}
    </td>
  </ng-container>

  <ng-container matColumnDef="failed_at">
    <th mat-header-cell *matHeaderCellDef>Hiba Ideje</th>
    <td mat-cell *matCellDef="let element">
      {{ element.failed_at * 1000 | date : "yyyy.MM.dd HH:mm" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="retry">
    <th mat-header-cell *matHeaderCellDef>Hiba Ideje</th>
    <td mat-cell *matCellDef="let element">
      <button
        payee-button
        width="shorter"
        height="short"
        (click)="retryJob(element); $event.stopPropagation()"
      >
        Újraküldés
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column -->
  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <ng-container *ngIf="expandedElement === element">
        <div class="detail-column">
          <ng-container *ngIf="element.retried_by">
            <div class="flex column">
              <h1>Retries</h1>
              <div *ngFor="let retried_by of element.retried_by">
                <div class="flex vcenter retry-item">
                  <div>
                    {{ retried_by.retried_at * 1000 | date : "yyyy.MM.dd." }}
                  </div>
                  <div class>{{ retried_by.status }}</div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="flex column">
            <h1>Hibaüzenet</h1>
            <span>{{ element.exception }}</span>
          </div>
        </div>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    (click)="toggleExpandedElement(element)"
    class="element-row"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    [class.expanded-row]="expandedElement === row"
    class="detail-row"
  ></tr>
</table>

<mat-paginator
  [disabled]="loading"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [length]="length"
  showFirstLastButtons
  (page)="pageChange($event)"
  class="mat-elevation-z1"
></mat-paginator>
