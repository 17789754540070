import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowEmailTestComponent } from './workflow-email-test.component';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PayeeInfoModule } from 'src/app/shared/payee-info/payee-info.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [
    WorkflowEmailTestComponent,
  ],
  imports: [
    CommonModule,
    PayeeFormFieldsModule,
    ReactiveFormsModule,
    PayeeInfoModule,
    PayeeIconModule,
    PayeeButtonsModule,
    MatCheckboxModule,
    MatSnackBarModule,
  ]
})
export class WorkflowEmailTestModule { }
