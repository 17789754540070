<nav id="szamlazzhu-nav" class="flex vcenter">
  <button payee-button width="shorter" routerLink="invoices" routerLinkActive="active">Számlák</button>
  <button payee-button width="shorter" routerLink="invoice-form" routerLinkActive="active">Számla Form</button>
  <button payee-button width="shorter" routerLink="kszi-info" routerLinkActive="active">Hitelező Számlái</button>
  <button payee-button width="short" routerLink="invoice-resend" routerLinkActive="active">Számla Újraküldése</button>
  <button payee-button width="shorter" routerLink="failed-jobs" routerLinkActive="active">Hibás műveletek</button>
</nav>
<main id="szamlazzhu-main">
  <router-outlet></router-outlet>
</main>
