import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type ChartParams = {
  from_date: string;
  to_date: string;
};

export type ChartResponse = {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: {
        x: any;
        y: any;
      }[];
    }[];
  };
};

export type ChartName = 'case-starts';

export type CaseStartsByPartnerResponse = {
  data: {
    headers: {
      id: string;
      label: string;
    }[];
    datasets: Record<string, Record<string, number>>;
  };
};

export type SuccessfulSoftCase = {
  client_name: string | null;
  client_country: string | null;
  client_postcode: string | null;
  client_settlement: string | null;
  client_street: string | null;
  client_tax_number: string | null;
  user_email: string;
  debtor_name: string | null;
  client_id: string;
  case_id: string;
  remaining_amount: number;
  original_amount: number;
  currency_iso: string;
  closed_at: string | null;
  state: string;
  product: string;
  product_type: string;
  partner: string | null;
};

export type SuccessfulSoftCasesResponse = {
  data: SuccessfulSoftCase[];
};

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  async getChart(chartName: ChartName, params: ChartParams): Promise<ChartResponse> {
    const url = `${environment.baseUrl}/api/charts/${chartName}`;
    const result = await axios.get(url, {
      params,
    });
    return result.data;
  }

  async getCaseStartsByPartner(params: ChartParams): Promise<CaseStartsByPartnerResponse> {
    const url = `${environment.baseUrl}/api/charts/get-case-starts-by-partner`;
    const result = await axios.get(url, {
      params,
    });
    return result.data;
  }

  async getSuccessfulSoftCases(params: ChartParams): Promise<SuccessfulSoftCasesResponse> {
    const url = `${environment.baseUrl}/api/charts/get-successful-soft-cases`;
    const result = await axios.get(url, {
      params,
    });
    return result.data;
  }
}
