<section id="partner-toolbar">
  <div class="flex vcenter">
    <button payee-button (click)="createPartner()">Partner Hozzáadása</button>
  </div>
</section>
<section id="partner-table-section">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1" id="partner-table">
    <ng-container matColumnDef="integration">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Partner ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.integration }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Partner Neve</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="api_key">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Partner Token</th>
      <td mat-cell *matCellDef="let element">
        {{ element.api_key }}
      </td>
    </ng-container>

    <ng-container matColumnDef="origin">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>User Source</th>
      <td mat-cell *matCellDef="let element">
        {{ element.origin }}
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        Partner Létrejötte
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.created_at | date : "yyyy.MM.dd." }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button payee-icon-button color="white" (click)="editPartner(element)">
          <payee-icon name="edit-grey"></payee-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
    ></tr>
  </table>
</section>
