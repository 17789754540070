import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceFormTableComponent } from './invoice-form-table.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';



@NgModule({
  declarations: [
    InvoiceFormTableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    PayeeFormFieldsModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
  ]
})
export class InvoiceFormTableModule { }
