import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { GetKsziInfoResponse, SzamlazzhuClientItem } from 'src/app/services/szamlazzhu/types';

@Component({
  selector: 'app-invoice-resend',
  templateUrl: './invoice-resend.component.html',
  styleUrls: ['./invoice-resend.component.scss']
})
export class InvoiceResendComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;
  loading = false;

  clientSearchSubject = new Subject<string>();
  clients: SzamlazzhuClientItem[] = [];

  get client(): FormControl { return this.formGroup.get('client') as FormControl; }
  get invoiceNumber(): FormControl { return this.formGroup.get('invoiceNumber') as FormControl; }

  ksziInfo?: GetKsziInfoResponse;
  ksziInterval?: NodeJS.Timeout;

  private readonly destroy = new Subject<void>();

  constructor(
    private szamlazzhuService: SzamlazzhuService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
  ) {
    this.formGroup = this.fb.group({
      client: [null, Validators.required],
      invoiceNumber: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.clientSearchSubject
      .pipe(
        takeUntil(this.destroy),
        debounceTime(1000),
      )
      .subscribe({
        next: async search => await this.setClients(search),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();

    clearInterval(this.ksziInterval);
  }

  async submit() {
    if (this.formGroup.invalid || this.loading) {
      return;
    }

    try {
      this.loading = true;
      await this.szamlazzhuService.resendInvoice(this.client.value.uuid, this.invoiceNumber.value);

      this.snackbar.open('Számla újraküldése folyamatban!', 'OK', { duration: 5000 });

      this.ksziInfo = await this.szamlazzhuService.getKsziInfo(this.client.value.uuid);
      clearInterval(this.ksziInterval);
      this.ksziInterval = setInterval(async () => {
        this.ksziInfo = await this.szamlazzhuService.getKsziInfo(this.client.value.uuid);
      }, 10_000);
    } catch (error) {
      console.error('Error while resending invoice', error);
      this.snackbar.open('Valami hiba történt!', 'OK', { duration: 5000 });
    } finally {
      this.loading = false;
    }
  }

  clientDisplayWith(item?: SzamlazzhuClientItem): string {
    return item?.name ?? '';
  }

  private async setClients(search: string): Promise<void> {
    const result = await this.szamlazzhuService.getSzamlazzhuClients({ search });
    this.clients = result.clients;
  }
}
