import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type ListFeesParams = {
  label?: string;
  product_id: number;
};

export type ListFeesResponse = {
  fees: {
    id: string;
    label: string;
  }[];
};

@Injectable({
  providedIn: 'root'
})
export class FeesService {
  async listFees(params: ListFeesParams): Promise<ListFeesResponse> {
    const url = `${environment.baseUrl}/api/fee`;
    const result = await axios.get<ListFeesResponse>(url, { params });
    return result.data;
  }
}
