<payee-popup
  class="edit-case-popup"
  title="Ügy szerkesztése"
  description="A popup nem ment magától, csak amikor megnyomja a mentés gombot!"
>
  <mat-progress-spinner
    *ngIf="!initialized"
    mode="indeterminate"
    color="accent"
  ></mat-progress-spinner>
  <form
    class="main-form grow1 hcenter"
    [formGroup]="formGroup"
    *ngIf="initialized"
  >
    <mat-card>
      <mat-card-title [class.error-label]="payeeCase.invalid"
        >Jogviszony</mat-card-title
      >
      <app-case-form
        [formGroup]="payeeCase"
        [legalRelationships]="legalRelationships"
        [claims]="claims"
      ></app-case-form>
    </mat-card>
    <mat-card>
      <mat-card-title [class.error-label]="claims.invalid"
        >Követelések</mat-card-title
      >
      <app-claims-form
        [legalRelationship]="payeeCase.value.legalRelationshipTypeId"
        [claims]="claims"
        [claimTypes]="claimTypes"
        [addClaim]="addClaim"
        [addClaimFile]="addClaimFile"
      ></app-claims-form>
    </mat-card>
    <mat-card>
      <mat-card-title [class.error-label]="client.invalid"
        >Hitelező</mat-card-title
      >
      <app-client-form
        [formGroup]="client"
        [client]="data?.client"
        [partyTypes]="partyTypes"
        [representativeTypes]="representativeTypes"
        [countries]="countries"
      ></app-client-form>
    </mat-card>
    <mat-card>
      <mat-card-title [class.error-label]="debtors.invalid">{{
        debtorTabLabel
      }}</mat-card-title>
      <app-debtors-form
        [debtors]="debtors"
        [debtorsData]="data?.debtors"
        [partyTypes]="partyTypes"
        [representativeTypes]="representativeTypes"
        [countries]="countries"
      ></app-debtors-form>
    </mat-card>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      [disabled]="formGroup?.invalid"
      (click)="submit()"
    >
      Mentés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" (click)="cancel()">
      Bezárás
    </button>
  </ng-template>
</payee-popup>
