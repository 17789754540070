import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeInfoModule } from 'src/app/shared/payee-info/payee-info.module';
import { CaseStartsComponent } from './case-starts/case-starts.component';
import { MetricsComponent } from './metrics.component';
import { RouterModule } from '@angular/router';
import { SoftPaidComponent } from './soft-paid/soft-paid.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';



@NgModule({
  declarations: [
    CaseStartsComponent,
    MetricsComponent,
    SoftPaidComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatProgressSpinnerModule,
    PayeeButtonsModule,
    PayeeFormFieldsModule,
    PayeeIconModule,
    PayeeInfoModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
  ]
})
export class MetricsModule { }
