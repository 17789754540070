import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { FailedJobItem, GetFailedJobsParams } from 'src/app/services/szamlazzhu/types';

@Component({
  selector: 'app-failed-jobs',
  templateUrl: './failed-jobs.component.html',
  styleUrls: ['./failed-jobs.component.scss']
})
export class FailedJobsComponent implements OnInit, OnDestroy {
  readonly dataSource = new MatTableDataSource<FailedJobItem>();
  readonly displayedColumns: string[] = [
    'name',
    'tags',
    'queue',
    'failed_at',
    'retry',
  ];

  expandedElement: FailedJobItem | null = null;
  loading = false;

  pageIndex = 0;
  pageSize = 5;
  length?: number;

  readonly setDataSubject = new Subject<void>();
  private readonly destroy = new Subject<void>();

  constructor(
    private szamlazzhuService: SzamlazzhuService,
  ) { }

  ngOnInit(): void {
    this.setDataSubject
      .pipe(
        startWith([undefined]),
        takeUntil(this.destroy),
        debounceTime(500),
      )
      .subscribe({
        next: async () => await this.setData(),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  pageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.setDataSubject.next();
  }

  toggleExpandedElement(element: FailedJobItem): void {
    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.expandedElement = element;
    }
  }

  async retryJob(element: FailedJobItem): Promise<void> {
    const job = await this.szamlazzhuService.retryFailedJob(element.id);
    Object.assign(element, job);
  }

  private async setData(): Promise<void> {
    const params: GetFailedJobsParams = {
      page: this.pageIndex,
      per_page: this.pageSize,
    };
    const result = await this.szamlazzhuService.getFailedJobs(params);
    this.dataSource.data = result.jobs;
    this.length = result.total;
  }
}
