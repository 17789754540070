import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { Product, ProductService } from 'src/app/services/product-service/product-service.service';

@Component({
  selector: 'app-edit-product-dialog',
  templateUrl: './edit-product-dialog.component.html',
  styleUrls: ['./edit-product-dialog.component.scss']
})
export class EditProductDialogComponent {
  readonly formGroup: FormGroup;
  submitLoading = false;

  get payee_fee(): FormControl { return this.formGroup.get('payee_fee') as FormControl; }
  get name(): FormControl { return this.formGroup.get('name') as FormControl; }
  get currency(): string { return this.product.currency; }

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private product: Product,
    private dialogRef: MatDialogRef<EditProductDialogComponent>,
    private helpersService: HelpersService,
    private productService: ProductService,
  ) {
    this.formGroup = this.fb.group({
      name: [this.product.name, [Validators.required, Validators.maxLength(255)]],
      payee_fee: [this.product.payee_fee, [this.helpersService.removeLetters, Validators.required, Validators.min(1)]],
    });
  }

  async submit(): Promise<void> {
    if (this.submitLoading || this.formGroup.invalid) {
      return;
    }
    try {
      this.submitLoading = true;

      const result = await this.productService.updateProduct(this.product.id, this.formGroup.value);

      this.dialogRef.close(result);
    } catch (error) {
      console.error('Error while updating product price');
    } finally {
      this.submitLoading = false;
    }
  }
}
