import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { LegalRelationship } from 'src/types';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { CaseFormGroup, ClaimForm, ClaimFormArray } from '../edit-case-dialog.component';
import { CasesService, LegalRelationshipFile } from 'src/app/services/cases/cases.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-case-form',
  templateUrl: './case-form.component.html',
  styleUrls: ['./case-form.component.scss']
})
export class CaseFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formGroup: CaseFormGroup;
  @Input() legalRelationships: LegalRelationship[] = [];
  legalRelationshipOptions: number[] = [];
  @Input() claims: ClaimFormArray;
  private claimsValuechanges?: Subscription;

  loading = false;
  showLegalBaseSpecification = false;

  get legalRelationshipFiles(): FormArray {
    return this.formGroup.get('files') as FormArray;
  }
  get legalRelationshipSpecification(): FormControl {
    return this.formGroup.get('legalRelationshipSpecification') as FormControl;
  }
  get legalRelationshipTypeId(): FormControl {
    return this.formGroup.get('legalRelationshipTypeId') as FormControl;
  }
  get description(): FormControl {
    return this.formGroup.get('description') as FormControl;
  }

  private destroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private casesService: CasesService,
  ) { }

  ngOnInit(): void {
    this.legalRelationshipTypeId.valueChanges
      .pipe(
        takeUntil(this.destroy),
        startWith(this.legalRelationshipTypeId.value),
      )
      .subscribe({
        next: (id: number) => {
          const legalRelationship = this.legalRelationships.find(lr => lr.id === id);

          this.showLegalBaseSpecification = legalRelationship?.code === 999;
        },
      });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if ('legalRelationships' in changes) {
      await this.filterLegalRelationshipOptions();
    }
    if ('claims' in changes) {
      if (this.claimsValuechanges) {
        this.claimsValuechanges.unsubscribe();
      }
      this.claims.valueChanges
        .pipe(
          takeUntil(this.destroy),
          map((values: ClaimForm[]) => values.map(v => v.claimType).join(',')),
          distinctUntilChanged(),
        )
        .subscribe({
          next: async () => {
            await this.filterLegalRelationshipOptions();
          }
        });
      await this.filterLegalRelationshipOptions();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  legalBaseEvidencesDropped(fileList: FileList): void {
    for (let i = 0; i < fileList.length; ++i) {
      const file = fileList.item(i) as File;
      this.addLegalBaseEvidenceFile(file);
    }
  }

  openLegalBaseEvidence(file: File | LegalRelationshipFile): void {
    if (file instanceof File) {
      window.open(URL.createObjectURL(file), '_blank');
    } else {
      window.open(file.storage_url, '_blank');
    }
  };

  removeLegalBaseFile(index: number): void {
    if (this.legalRelationshipFiles.length <= index) {
      return;
    }
    this.legalRelationshipFiles.removeAt(index);
  }

  readonly legalBaseTypeDisplayWith = (id: number): string => {
    return this.legalRelationships.find(lr => lr.id === id)?.label || '';
  };

  private async filterLegalRelationshipOptions(): Promise<void> {
    const claimTypeIds: number[] = this.claims.controls
      .map(control => control.value.claimType)
      .filter(ct => !!ct) as number[];
    if (claimTypeIds.length === 0) {
      this.legalRelationshipOptions = this.legalRelationships.map(lr => lr.id);
    } else {
      this.legalRelationshipOptions = await this.casesService.getAllowedLegalRelationships(claimTypeIds);
    }
  }

  private addLegalBaseEvidenceFile(file: File | LegalRelationshipFile): void {
    this.legalRelationshipFiles.push(this.fb.control(file));
  }

  readonly environment = environment;
}
