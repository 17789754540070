<header class="flex vcenter">
  <button color="grey-light" payee-icon-button (click)="logout()">
    <payee-icon name="logout-grey"></payee-icon>
  </button>
  <button payee-button color="grey-light" class="name-button">
    {{ userName }}
  </button>
</header>

<main>
  <h1>Workflow emailek küldése</h1>
  <h2 class="top-margin-15">
    Erre az email címre fog kimenni az email: {{ userEmail }}
  </h2>
  <form [formGroup]="formGroup" class="flex column top-margin-30">
    <div class="flex vcenter" style="gap: 16px">
      <button payee-button (click)="submit()">Küldés</button>
      <button payee-button (click)="togglaAll()">Összes kijelölése</button>
    </div>
    <payee-info width="widest" class="top-margin-30">
      <div>Bármelyik létező ügy azonosítója, pl. P1001.</div>
      <div>Ennek az ügynek az adataival fog az email feltöltődni.</div>
    </payee-info>
    <payee-form-field width="widest" color="white" class="top-margin-5">
      <span payee-label color="black">Ügyazonosító</span>
      <input payee-input formControlName="payee_case_id" />
    </payee-form-field>
    <mat-checkbox
      *ngFor="let t of templateOptions"
      (change)="onTemplateChanged($event, t)"
      [checked]="getIndexOfTemplate(t) >= 0"
    >
      <strong>{{ t.subject || "-" }}&nbsp;</strong>
      <span>{{ t.language }}&nbsp;</span>
      <span color="grey-dark">{{ t.class }}</span>
    </mat-checkbox>
    <button payee-button (click)="submit()">Küldés!</button>
  </form>
</main>
