<mat-progress-spinner
  diameter="100"
  mode="indeterminate"
  *ngIf="loading"
></mat-progress-spinner>

<form class="filter-form flex" [formGroup]="filterForm">
  <payee-form-field width="short">
    <span payee-label>Ettől a dátumtól kezdve</span>
    <payee-datepicker formControlName="from_date"> </payee-datepicker>
  </payee-form-field>
  <payee-form-field width="short">
    <span payee-label>Eddig a dátumig</span>
    <payee-datepicker formControlName="to_date"> </payee-datepicker>
  </payee-form-field>
  <button payee-button (click)="getCharts()" width="short">Frissítés</button>
</form>

<div class="flex case-starts-chart-container">
  <div style="width: 800px; height: 400px"><canvas #casesStarted></canvas></div>
  <div>
    <payee-info>
      A grafikon-on a negatív értékek az elutasított ügyeket jelentik.
    </payee-info>
    <div *ngFor="let entry of casesStartedDataSums | keyvalue">
      <strong>{{ entry.key }}:</strong>
      <span>{{ entry.value }}</span>
    </div>
  </div>
</div>

<table *ngIf="!!casesStartedByPartners" class="case-starts-by-partner-table">
  <tr>
    <th *ngFor="let h of casesStartedByPartners.data.headers">
      {{ h.label }}
    </th>
  </tr>
  <tr *ngFor="let dataset of casesStartedByPartners.data.datasets | keyvalue">
    <td *ngFor="let h of casesStartedByPartners.data.headers">
      {{ dataset.value[h.id] || '' }}
    </td>
  </tr>
</table>
