<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z1"
  multiTemplateDataRows
>
  <ng-container matColumnDef="detailsToggle">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <payee-icon name="arrow-down-grey-no-shaft"></payee-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="payeeId">
    <th mat-header-cell *matHeaderCellDef>Ügyazonosító</th>
    <td mat-cell *matCellDef="let element">
      {{ element.payee_case_reference_id }}
    </td>
  </ng-container>

  <ng-container matColumnDef="debtors">
    <th mat-header-cell *matHeaderCellDef>FELEK</th>
    <td mat-cell *matCellDef="let element">
      <div class="client">{{ element.parties.client_name }} vs</div>
      <div>
        <strong>
          {{ element.parties.debtor_name }}
        </strong>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>STÁTUSZ</th>
    <td mat-cell *matCellDef="let element">
      <div class="status-box">
        <div
          class="state"
          [style.color]="element.state.state_label_text_color"
          [style.background-color]="element.state.state_label_background_color"
        >
          {{ element.state.state_label }}
        </div>
        <div class="stage">
          <!-- <span class="prefix">Szakasz:&nbsp;</span> -->
          <span class="label">{{ element.state.stage_label }}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="claimsSumAmount">
    <th mat-header-cell *matHeaderCellDef>KÖVETELÉS</th>
    <td mat-cell *matCellDef="let element">
      <div class="claims-sum-amount-box">
        <span class="current">
          {{ element.claim.remaining_amount | number : "1.0" : "hu" }}
          {{ element.claim.currency }}
        </span>
        <span class="original">
          {{ element.claim.original_amount | number : "1.0" : "hu" }}
          {{ element.claim.currency }}
        </span>
      </div>
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="debug">
    <th mat-header-cell *matHeaderCellDef>
      Debug
    </th>
    <td mat-cell *matCellDef="let element">
      <button
        payee-icon-button
        [matMenuTriggerFor]="debugMenu"
        (menuOpened)="onActionsMenuOpen(element)"
        (menuClosed)="onActionsMenuClosed()"
        class="actions-menu-button"
        color="white"
      >
        <payee-icon [name]="environment.MORE_ICON"></payee-icon>
      </button>

      <mat-menu #debugMenu>
        <button
          mat-menu-item
          (click)="openFormOfElement(element)"
        >
          Form
        </button>
      </mat-menu>
    </td>
  </ng-container> -->

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        payee-icon-button
        [matMenuTriggerFor]="actionsMenu"
        (menuOpened)="onActionsMenuOpen(element)"
        (menuClosed)="onActionsMenuClosed()"
        class="actions-menu-button"
        color="white"
      >
        <payee-icon [name]="environment.MORE_ICON"></payee-icon>
      </button>

      <mat-menu #actionsMenu>
        <button mat-menu-item (click)="openEditCaseDialog(element.id)">
          Ügy szerkesztése
        </button>
        <button
          id="{{i}}"
          mat-menu-item
          *ngFor="let event of element.actions; let i = index"
          (click)="runTransitionWithDialog(element, event)"
        >
          {{ event.label }}
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <!-- Expanded Content Column -->
  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <div
        class="element-detail alt-theme flex column"
        [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
      >
        <mat-tab-group dynamicHeight>
          <mat-tab label="Események" *ngIf="expandedElement === element">
            <app-case-events [caseId]="element.id"></app-case-events>
          </mat-tab>
          <mat-tab label="Részletek" *ngIf="expandedElement === element">
            <app-case-details
              [caseId]="element.id"
              (filterCase)="filterHistoricCase($event)"
            ></app-case-details>
          </mat-tab>
        </mat-tab-group>
      </div>
    </td>
  </ng-container>

  <!-- <tr mat-header-row *matHeaderRowDef="filterHeader"></tr> -->
  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
    class="main-header"
  ></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    class="element-row mat-elevation-z2"
    [class.expanded-row]="expandedElement === element"
    (click)="updateExpandedElement(element)"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="detail-row"
  ></tr>
</table>

<mat-paginator
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [length]="length"
  (page)="pageChange($event)"
  disableUnderline
  class="mat-elevation-z1"
></mat-paginator>
