<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <h1 class="top-margin-15">Kedvezmény törlése</h1>
  </ng-template>

  <div class="data-row">
    <div class="data-title">Kedvezményezett típusa</div>
    <div class="data-value">
      {{ productDiscount.product_discountable.type }}
    </div>
  </div>
  <div class="data-row">
    <div class="data-title">Kedvezményezett neve</div>
    <div class="data-value">
      {{ productDiscount.product_discountable.name }}
    </div>
  </div>
  <div class="data-row">
    <div class="data-title">Kedvezmény típusa</div>
    <div class="data-value">
      {{ productDiscount.discount_type }}
    </div>
  </div>
  <div class="data-row">
    <div class="data-title">Kedvezmény mértéke</div>
    <div class="data-value" *ngIf="productDiscount.discount_type === 'amount'">
      {{ productDiscount.discount }}
      {{ productDiscount.product.currency_iso }}
    </div>
    <div
      class="data-value"
      *ngIf="productDiscount.discount_type === 'percentage'"
    >
      {{ productDiscount.discount }} %
    </div>
  </div>
  <div class="data-row">
    <div class="data-title">Termék neve</div>
    <div class="data-value">
      {{ productDiscount.product.name }}
    </div>
  </div>
  <div class="data-row">
    <div class="data-title">Díj neve</div>
    <div class="data-value">
      {{ productDiscount.fee.label }}
    </div>
  </div>

  <ng-template #payeePopupSubmitTemplate>
    <button (click)="submit()" payee-button width="wide">Törlés</button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      (click)="cancel()"
      color="white"
      [isBorderButton]="true"
      width="wide"
    >
      Mégsem
    </button>
  </ng-template>
</payee-popup>
