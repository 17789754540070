<form class="filter-form flex" [formGroup]="filterForm">
  <payee-form-field width="short">
    <span payee-label>Ettől a dátumtól kezdve</span>
    <payee-datepicker formControlName="from_date"> </payee-datepicker>
  </payee-form-field>
  <payee-form-field width="short">
    <span payee-label>Eddig a dátumig</span>
    <payee-datepicker formControlName="to_date"> </payee-datepicker>
  </payee-form-field>
  <button payee-button (click)="getCharts()" width="short">Frissítés</button>
</form>

<table mat-table [dataSource]="dataSource" matSort>
  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ column }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element[column] }}
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
    class="main-header"
  ></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    class="element-row mat-elevation-z2"
  ></tr>
</table>
