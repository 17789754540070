<header class="flex vcenter">
  <button color="grey-light" payee-icon-button (click)="logout()">
    <payee-icon name="logout-grey"></payee-icon>
  </button>
  <button payee-button color="grey-light" class="name-button">
    {{ userName }}
  </button>
</header>

<main class="flex column">
  <h1>Kedvezmények</h1>
  <button
    payee-button
    (click)="createProductDiscount()"
    class="top-margin-25 bottom-margin-15"
  >
    Kedvezmény létrehozása
  </button>
  <table
    *ngIf="!loading"
    mat-table
    [dataSource]="dataSource"
    class="top-margin-20 mat-elevation-z1"
  >
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>Termék neve</th>
      <td mat-cell *matCellDef="let element">
        {{ element.product.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fee">
      <th mat-header-cell *matHeaderCellDef>Díj neve</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fee.label }}
      </td>
    </ng-container>

    <ng-container matColumnDef="product_discountable_type">
      <th mat-header-cell *matHeaderCellDef>Kedvezményezett típusa</th>
      <td mat-cell *matCellDef="let element">
        {{ element.product_discountable.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="product_discountable_name">
      <th mat-header-cell *matHeaderCellDef>Kedvezményezett neve</th>
      <td mat-cell *matCellDef="let element">
        {{ element.product_discountable.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="discount">
      <th mat-header-cell *matHeaderCellDef>Kedvezmény mértéke</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.discount_type === 'percentage'">
          {{ element.discount }} %
        </span>
        <span *ngIf="element.discount_type === 'amount'">
          {{ element.discount }} {{ element.product.currency_iso }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          color="white"
          payee-icon-button
          (click)="editProductDiscount(element)"
        >
          <payee-icon name="edit-grey"></payee-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          color="white"
          payee-icon-button
          (click)="deleteProductDiscount(element)"
        >
          <payee-icon name="trash-red"></payee-icon>
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="main-header"
    ></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [length]="length"
    (page)="pageChange($event)"
    disableUnderline
    class="mat-elevation-z1"
  ></mat-paginator>
</main>

<mat-progress-spinner
  *ngIf="loading"
  class="loading-spinner"
  color="accent"
  diameter="100"
  mode="indeterminate"
></mat-progress-spinner>
