import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetEditCaseResponse } from 'src/app/services/cases/cases.service';
import { Debtor, PartyType, RepresentativeType } from 'src/types';
import { DebtorFormArray } from '../edit-case-dialog.component';
import { environment } from '../../../../environments/environment';
import { Country } from 'src/app/services/countries/countries.service';

@Component({
  selector: 'app-debtors-form',
  templateUrl: './debtors-form.component.html',
  styleUrls: ['./debtors-form.component.scss']
})
export class DebtorsFormComponent implements OnChanges {
  @Input() debtors: DebtorFormArray;
  @Input() debtorsData: GetEditCaseResponse["debtors"] = [];
  @Input() partyTypes: PartyType[] = [];
  @Input() representativeTypes: RepresentativeType[] = [];
  @Input() countries: Country[] = [];

  partyTypeOptions: number[] = [];
  representativeTypeOptions: number[] = [];
  countryOptions: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.representativeTypes) {
      this.representativeTypeOptions = changes.representativeTypes.currentValue.map((rt: RepresentativeType) => rt.id);
    }
    if (changes.partyTypes) {
      this.partyTypeOptions = changes.partyTypes.currentValue.map((pt: PartyType) => pt.id);
    }
    if (changes.countries) {
      this.countryOptions = changes.countries.currentValue.map((country: Country) => country.id);
    }
  }

  readonly repTypeDisplayWith = (id: number): string => {
    return this.representativeTypes.find(rt => rt.id === id)?.label || '';
  };

  readonly partyTypeDisplayWith = (id: number): string => {
    return this.partyTypes.find(pt => pt.id == id)?.label || '';
  };

  readonly countryDisplayWith = (id: string): string => {
    return this.countries.find(country => country.id === id)?.name || '';
  };

  readonly environment = environment;
}
