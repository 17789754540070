import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import {
  GetFailedJobsParams,
  GetFailedJobsResponse,
  GetInvoiceFormDataTableParams,
  GetInvoicesDataTableParams,
  GetInvoicesDataTableResponse,
  GetKsziInfoResponse,
  GetKsziStatusResponse,
  GetSzamlazzhuClientsParams,
  GetSzamlazzhuClientsResponse,
  RetryFailedJobResponse,
  SzamlazzhuInvoiceStepData,
  ToggleKsziParams,
} from './types';

@Injectable({
  providedIn: 'root'
})
export class SzamlazzhuService {
  async getInvoicesDataTable(params: GetInvoicesDataTableParams): Promise<GetInvoicesDataTableResponse> {
    const url = `${environment.baseUrl}/api/invoice-datatable`;
    const result = await axios.get(url, { params });
    return result.data;
  }

  async getInvoiceFormDataTable(caseId: string, params: GetInvoiceFormDataTableParams): Promise<SzamlazzhuInvoiceStepData> {
    const url = `${environment.baseUrl}/api/invoice-form-datatable/${caseId}`;
    const result = await axios.get(url, { params });
    return result.data;
  }

  async getKsziInfo(clientId: string): Promise<GetKsziInfoResponse> {
    const url = `${environment.baseUrl}/api/kszi-info/${clientId}`;
    const result = await axios.get(url);
    return result.data;
  }

  async getKsziStatus(clientId: string): Promise<GetKsziStatusResponse> {
    const url = `${environment.baseUrl}/api/kszi-status/${clientId}`;
    const result = await axios.get(url);
    return result.data;
  }

  async resendInvoice(clientId: string, invoiceNumber: string): Promise<void> {
    const url = `${environment.baseUrl}/api/resend-invoice/${clientId}/${invoiceNumber}`;
    await axios.post(url);
  }

  async getSzamlazzhuClients(params: GetSzamlazzhuClientsParams): Promise<GetSzamlazzhuClientsResponse> {
    const url = `${environment.baseUrl}/api/szamlazzhu-client`;
    const result = await axios.get<GetSzamlazzhuClientsResponse>(url, { params });
    return result.data;
  }

  async toggleKszi(clientId: string, params: ToggleKsziParams): Promise<void> {
    const url = `${environment.baseUrl}/api/toggle-kszi/${clientId}`;
    await axios.post(url, params);
  }

  async getFailedJobs(params: GetFailedJobsParams): Promise<GetFailedJobsResponse> {
    const url = `${environment.baseUrl}/api/failed-jobs`;
    const result = await axios.get<GetFailedJobsResponse>(url, { params });
    return result.data;
  }

  async retryFailedJob(id: string): Promise<RetryFailedJobResponse> {
    const url = `${environment.baseUrl}/api/failed-jobs/retry/${id}`;
    const result = await axios.post<RetryFailedJobResponse>(url);
    return result.data;
  }
}
